.logo {
    width: 180px;
    cursor: pointer;
}

.header-container {
    display: flex;
    flex-direction: row;
    padding: 18px 50px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    background:#161933!important;
    box-sizing: border-box;
}

.top-prices-wrap {
  display: flex;

}

.top-prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 100px;
  font-size: 12px;
}

.top-prices p{
  color: rgb(242, 198, 255);
}

.top-prices span {
  font-weight: 700;
}

.connect-button-wrap button {
  background: #333FE8;
  border-radius: 20px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  outline: none;
  border: none;
}

.connect-button-wrap button:hover {
  background: rgb(255, 255, 255);
  color: rgb(13, 86, 243);
}

.airdrop-icon {
  width: 70px;
  position: absolute;
  right: 23vw;
  -webkit-animation:spin 4s linear ;
    -moz-animation:spin 4s linear ;
    animation:spin 4s linear infinite;
    cursor: pointer;
}


@keyframes spin { 
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}