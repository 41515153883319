.menu-container {
    display: flex;
    flex-direction: row;
    
}

.menu-container {
    display: flex;
    margin: 20px auto;
    width: 35%;
    justify-content: center;
    border: 1px solid rgb(63, 63, 112);
    border-radius: 30px;
    padding: 4px 0;
    background:#1f2242;
}

.menu-container a {
    margin: 0 20px;
    color: rgb(203, 203, 212);
    text-decoration: none;
    font-size: 12px;
    padding: 12px 16px;
    border-radius: 30px;
    font-weight: 300;
}

.menu-container a:hover {
    background: rgb(30, 30, 63);
    color: rgb(255, 255, 255);
}