.trixx-staking-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    
}

.input-staking-wrap {
    display: flex;
    flex-direction: column;
    background: linear-gradient(255.12deg, #2d2d52 70%, #282844 100%);
    width: 30%;
    border-radius: 10px;
    padding: 10px 20px;
    align-items: center;
    padding-top: 30px;
    margin-right: 30px;

}


.input-staking-wrap input {
    border: none;
    outline: none;
    font-size: 25px;
    font-weight: 700;
    background: linear-gradient(255.12deg, #464670 70%, #3b3b5c 100%);
    color: rgb(158, 166, 214);
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px 25px;
}


.input-staking-wrap .trixx-deposit-button{
    border: none;
    outline: none;
    background: #333FE8;
    color: white;
    border-radius: 30px;
    padding: 15px 10px;
    width: 200px;
    margin-top: 15px;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 30px;
}

.input-staking-wrap .trixx-deposit-button:hover{
    background: #313796;

}

.input-staking-wrap p{
    margin-top: 10px;
    font-size: 13px;
    color: rgb(20, 170, 170);
}

.input-staking-wrap span{
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.footer-info-wrap-invest {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
}

.plan-selector-wrap {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 78%;
   flex-wrap: wrap;
}

.plan-selector-wrap button {
    border: 1px solid #333FE8;
    outline: none;
    background: transparent;
    color: white;
    border-radius: 5px;
    padding: 15px 10px;
    width: 100px;
    margin-top: 15px;
    cursor: pointer;
}

.plan-selector-wrap button:hover {
    opacity: 0.8;
}


.plan-selector-beta {
    border: none !important;
    background: #3e3099!important;
    color: #fff !important;

}

.plan-selector-beta-off {
    border: none !important;
    background: transparent !important;
    color: #9187d4 !important;
    border: 1px solid #3e3099 !important;
}

.plan-selector-alpha {
    border: none !important;
    background: #7b1afa !important;;
    color: #fff !important;
}

.plan-selector-alpha-off {
    border: none !important;
    background: transparent !important;
    color: #8757c7 !important;
    border: 1px solid #7b1afa !important;
}

.plan-selector-alpha:hover {
    border: none !important;
    background: #7b1afa;
    color: #fff !important;
}

.plan-selector-gamma {
    border: none !important;
    background: #a81bee !important;
    color: #fff !important;

}

.plan-selector-gamma-off {
    border: none !important;
    background: transparent !important;
    color: #a809f9 !important;
    border: 1px solid #a809f9 !important;

}

.plan-selector-gamma:hover {
    border: none !important;
    background: #a809f9;
    color: #fff !important;
}

.footer-info-wrap-invest {
    bottom: -20px;
}

.plan-brief {
    background-color: #3e3099;
    border-radius: 10px;
    padding: 10px 10px;
    width: 77%;
    margin-top: 10px;
    box-sizing: border-box;
   
}


.plan-brief p {
   color: rgb(243, 245, 252);
   font-style: italic;
   font-weight: 600;
   padding-bottom: 5px;
}