.bmx-staking-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    flex-direction: column;
}

.bmx-staking-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.bmx-staking-box {
    width: 300px;
    height: 300px;
    background: linear-gradient(255.12deg, #0e0c1d 0%, #181838 100%);
    margin: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0px 3px 51px #1b1b36;
}

.bmx-staking-box .earned-amount-bmx {
  color: #d6ccff;
  font-size: 40px;
  margin-top: -50px;
}

.bmx-staking-box .staked-amount-bmx {
    color: #d6ccff;
    font-size: 40px;
  }

.bmx-staking-box .credit-amount-bmx {
    color: #d6ccff;
    font-size: 35px;
    margin-top: 15px;
  }

.bmx-staking-box span {
    color: #e46fd0;
    font-size: 12px;
}

.bmx-staking-box .bmx-withdraw-btn {
    position: absolute;
    bottom: 22px;
    width: 70%;
    border: none;
    border-radius: 30px;
    font-size: 15px;
    padding: 12px 10px;
    outline: none;
    cursor: pointer;
    background: linear-gradient(255.12deg, #6734b9 0%, #eb6c92 100%);
    color: white;
    font-weight: 800;
}

.bmx-staking-box .bmx-withdraw-btn:hover {
    opacity: 0.8;
}

.bmx-add-btn {
    color: #d6ccff
}

.bmx-staking-box input{
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 20px;
  background: linear-gradient(255.12deg, #292449 0%, #24244b 100%);
  color: #d6ccff;
  font-weight: 800;
  font-size: 15px;
}

.terminate-stake-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.terminate-stake-wrap button {
    width: 200px;
    border: none;
    border-radius: 30px;
    font-size: 15px;
    padding: 12px 10px;
    outline: none;
    cursor: pointer;
    background: linear-gradient(255.12deg, #3e1a79 0%, #ec0505 100%);
    color: white;
    font-weight: 800;
    margin-top: 20px;
}

.terminate-stake-wrap button:hover {
    opacity: 0.8;
}

.terminate-stake-wrap span {
    font-size: 12px;
    color: rgb(234, 224, 253);
    margin-bottom: 10px;
}


.bmx-credit-box {
    background: linear-gradient(255.12deg, #2b0b5e 70%, #943f59 100%) !important;
}

.bmx-token-balance {
    color: #d6ccff !important
}

.read-more-link {
    color: rgb(245, 116, 170);
    font-weight: 800;

}

.bmx-price {
    position: absolute;
    top: 5px;
    font-size: 15px;
    color: #d6ccff;
    font-weight: 300 !important;
}