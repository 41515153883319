.about-container {
    width: 60%;
    margin: 0 auto;
    background: rgb(26, 26, 41);
    border-radius: 20px;
    padding: 20px 50px;
    color: rgb(49, 49, 49);
    margin-top: 50px;
    padding-bottom: 30px;
    font-family: 'Roboto', sans-serif !important;
}

.about-container p {
    font-weight: 500;
}

.minter-words {
    background: linear-gradient(255.12deg, #2b0b5e 70%, #943f59 100%) !important;
    color: rgb(236, 230, 255);
    padding: 15px 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.copied-wrap {
    background: linear-gradient(255.12deg, #2b0b5e 70%, #eb6c92 100%) !important;
    padding: 10px 20px;
    border-radius: 20px;
    color: rgb(221, 221, 221);
    font-weight: 500;
    margin-bottom: 5px;
}

.loading-div {
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
}

.loading-div img {
    width: 45px;
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
 }

 .minting-img {
     width: 30px;
     -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
    margin-right: 20px;
 }

 .about-div-wrap {
     background: rgb(41, 41, 78) ;
     padding: 20px 20px 40px 20px ;
     border-radius: 20px;
     margin-bottom: 10px;
     color: rgb(253, 253, 253);
 }

 .about-div-wrap p {
    padding-left: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    color: rgb(223, 223, 223);
}

 .about-para-title {
     padding-left: 10px;
     font-size: 25px;
     color: #ff5f8f;
     margin-top: 20px;
     margin-bottom: 20px;
     
 }

 .days-left-wrap {
     background: linear-gradient(255.12deg, #181535 100%, #05040a 0%);
     color: #ecd7a0;
     font-weight: 800;
     width: 50px;
     height: 50px;
     border-radius: 50px;
     font-size: 15px;
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
     right: 15px;
     bottom: 15px;
     
 }

 .partners-wrap-ft {
     display: flex;
 }

 .partners-wrap-ft img{ 
    width: 100px;
    cursor: pointer;

}



@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
