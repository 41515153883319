.token-screen-search-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    justify-content: center;
    align-items: center;
}

.token-screen-search-wrap input {
    border: 1px solid rgb(45, 45, 129);
    width: 400px;
    background: transparent;
    padding: 20px 30px;
    font-size: 20px;
    font-weight: 200;
    outline: none;
    color: rgb(143, 137, 202);
    border-radius: 7px;
    text-align: center;
}

.token-screen-details-wrap {
    margin-top: 50px;
    border: 1px solid rgb(45, 45, 129);
    width: 50%;
    background:  rgb(24, 24, 51);
    height: 400px;
    color: rgb(194, 191, 191);
    border-radius: 10px;
    position: relative;
}

.token-screen-price-wrap {
    position: absolute;
    right: 40px;
    top: 30px;
    display: flex;
    flex-direction: column;
}

.token-screen-price-wrap h5 {
    color: #17FF90;
    font-size: 35px;
    margin: 0;
}

.token-screen-price-wrap span {
    font-size: 12px;
}

.token-screen-details-wrap h1 {
    text-align: center;
    margin-top: 100px;
    font-weight: 300;
    color:  rgb(45, 45, 129);
}












::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(62, 62, 90);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(62, 62, 90);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: rgb(62, 62, 90);
  }