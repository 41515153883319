.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 30px 35px;
    margin-top: 50px;
    background: #191732;
    border-top: 1px solid #333358;;
}

.footer-container p {
    margin: 0;
    font-size: 12px;
    color: #d26ceb;
    opacity: 0.5;
}

.footer-container a {
    color: rgb(204, 145, 18);
    font-size: 11px;
    text-decoration: none;
    font-weight: 700;
}