.charts-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.charts-box {
    display: flex;
    flex-direction: row;
    background: rgb(24, 24, 48) !important;
    padding: 5px 30px;
    border-radius: 7px;
    width: 80%;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid rgb(45, 45, 75);
}

.charts-price-wrap {
    display: flex;
    align-items: center;
    height: 80px;
}

.charts-box h5 {
    font-weight: 900;
    color: #eb6c92;
    width: 25%;
    padding-left: 10px;
}

.charts-box img {
    width: 40px;
}

.charts-box p {
    font-size: 14px;
    font-weight: 700;
    width: 20%;
}

.charts-box-header {
    background: transparent !important;
    margin-bottom: 0;
    padding: 0 30px;
    color: rgb(187, 187, 187) !important;
    margin-bottom: 5px;
    border: none;
}

.charts-box-header h5 {
    color: rgb(218, 216, 216) !important;

}

.charts-search-wrap {
    display: flex;
    width: 70%;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
}

.charts-search-wrap input {
    background: transparent;
    border: 1px solid #000;
    border-radius: 20px;
    outline: none;
    padding: 15px 20px;
    width: 300px;
    font-weight: 700;
    font-size: 20px;
    margin-right: 10px;
    outline: none;
    color: rgb(219, 219, 219);
}

.charts-search-wrap button {
    background: rgb(11, 11, 26);
    color: #777;
    border: none;
    border-radius: 20px;
    padding: 17px 15px;
    width: 150px;
    outline: none;
    cursor: pointer;
}

.charts-search-wrap button:hover {
    background: rgb(8, 8, 17);
    color: rgb(175, 173, 173);
}

.charts-links-wrap {
    display: flex;
    width: 75%;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
}

.charts-links-wrap button {
     border: none;
     width: 200px;
     height: 40px;
     margin: 0 10px;
     outline: none;
     border-radius: 4px;
     cursor: pointer;
}

.charts-links-wrap button:nth-child(1) {
    background: linear-gradient(255.12deg, #9132df 20%, #1854ee 50%);
    color: #fff;
    font-weight: 700;
}


.charts-links-wrap button:nth-child(2) {
    background: linear-gradient(255.12deg, #00ff83 0%, #e08814 100%);
    color: #fff;
    font-weight: 700;
}

.charts-links-wrap button:nth-child(1):hover {
    background: linear-gradient(255.12deg, #8043E2 0%, #4274f3 100%);
    color: #fff;
    font-weight: 700;
}

.charts-links-wrap button:nth-child(2):hover {
    background: linear-gradient(255.12deg, #5af7ab 0%, #e08814 100%);
    color: #fff;
    font-weight: 700;
}
