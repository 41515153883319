.trixx-h1 {
    text-align: center;
    margin-top: 50px;
    padding-left: 5%;
}

.trixx-h1 h1{
    font-weight: 500;
    word-spacing: 2px;
    font-family: 'Poppins', sans-serif;
}

.trixx-span {
    background: #333FE8;
    border-radius: 50%;
    padding: 2px 20px;
    font-size: 30px;
}

.trixx-h1 p {
    color: #6c71ac;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin-top: 10px;
}

.withdraw-btn-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}


.withdraw-btn-wrap button{
    border: none;
    outline: none;
    background: #333FE8;
    color: white;
    border-radius: 30px;
    padding: 10px 10px;
    width: 200px;
    margin-top: 15px;
    cursor: pointer;
}

.withdraw-btn-wrap button:nth-child(2){
    background: #0b9b70;
}

.withdraw-btn-wrap button:hover{
    background: #1e2691;
}

.withdraw-btn-wrap button:nth-child(2):hover{
    background: #0b6b4e;
}

.footer-info-wrap {
    background: #333FE8;
    padding: 35px 13vw;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
}

.footer-info-wrap p {
    font-size: 12px;
    color: rgb(179, 182, 181);
    font-style: italic;
}

.footer-info-wrap span {
    font-size: 15px;
    color: rgb(211, 214, 216);
    font-style: normal
}

.airdrop-box-wrap {
    height: 420px;
    width: 400px;
    background: linear-gradient(255.12deg, #6e1e6e 10%, #2f2f8f 100%);
    border-radius: 20px;
    position: fixed;
    left: 35vw;
    top: 20vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.submit-address-input {
    width: 300px;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 10px 0;
    font-weight: 600;
}

.airdrop-box-wrap h3{
    opacity: 0.9;
    font-size: 45px;
}

.airdrop-box-wrap h5{
    color: #9398ce;
}

.wallet-address-wrap {
    text-align: center;
    margin-top: 30px;
    font-family: "poppin";
}

.wallet-address-wrap p {
    font-size: 15px;
    background: linear-gradient(255.12deg, #571857 10%, #2f2f8f 100%);
    padding: 5px 20px;
    border-radius: 30px;
    font-family: "poppin";
    font-weight: bold;
    opacity: 0.8;
}

.airdrop-box-icon {
    width: 70px;
    margin: 30px 0;
    opacity: 0.5;
  }

  .airdrop-box-wrap button {
      margin-top: 50px;
      border: none;
      border-radius: 30px;
      padding: 10px 30px;
      font-family: "poppin";
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      background: #6e2e88;
      color: rgb(214, 188, 214);
  }

  .airdrop-box-wrap button:hover {
    background: #9a72aa;
    color: rgb(34, 39, 82);
}

.submit-address-btn {
    margin-top: 0px !important;
    border: none;
      border-radius: 30px;
      padding: 10px 30px;
      font-family: "poppin";
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      background: #6e2e88;
      color: rgb(214, 188, 214);
}

.airdrop-box-wrap span {
   padding: 20px;
   text-align: left;
   font-size: 12px;
   opacity: 0.5;
   font-style: italic;
}

.airdrop-close-icon {
    position: absolute;
    width: 25px;
    right: 20px;
    top: 15px;
    cursor: pointer;
    opacity: 0.5;
}

.airdrop-close-icon:hover {
    opacity: 0.9;
}

.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    margin-left: 5px;
    margin-top: 20px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin-top: 10px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
  








.color {
    color: #1c1c31
}

.body-container {
    display: flex;
    max-width: 80vw;
    width: 80vw;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    
}

.deposit-body-container {
    display: flex;
    max-width: 80vw;
    width: 78vw;
    flex-direction: column;
    margin: 50px auto; 
    background: linear-gradient(255.12deg, #2d2d52 70%, #282844 100%);
    border-radius: 10px;
    
}

.protocol-details-wrap {
    display: flex;
    align-items: flex-start;
}

.global-stats-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;

}

.global-stats-box {
    display: flex;
    flex-direction: column;
    background: linear-gradient(255.12deg, #2d2d52 70%, #282844 100%);
    border-radius: 10px;
    margin: 0 20px;
    padding: 30px 15px;

  
}

.global-stats-box hr {
    width: 100%;
    opacity: 0.1
}

.global-stats-wrap {
    width: 60%;
}

.wallet-stats-wrap {
    width: 40%;
}

.stat-box-wrap {
    display: flex;
    justify-content: space-between;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 20px;
}

.stat-box-wrap div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stat-box-wrap p {
    color: #bfb8c5;
    margin-bottom: 3px;
}

.stat-box-wrap span {
    font-size: 14px;
    color: #f5f5f5;
    font-weight: 700;
}

.staking-plans-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}

.anytime-plans-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    max-width: 97%;
    margin-left: 19px;
}

.plans-box-wrap {
    display: flex;
    flex-direction: column;
    background: linear-gradient(255.12deg, #242444 70%, #282844 100%);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    width: 300px;
    height: 400px;
    position: relative;
}

.flex-div-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}

.flex-div-wrap-header {
    display: flex;
    justify-content: space-between;
}

.flex-div-wrap p {
    font-size: 14px;
    opacity: 0.8;
}

.flex-div-wrap span {
    color: rgb(183, 210, 250);
    font-weight: 700;
    font-size: 20px;
}

.days-locked {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 40px 0;

}

.days-locked div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    color: rgb(9, 77, 202);
    position: relative;
}


.days-locked h3{
    font-size: 35px;
    margin-top: -8px;
}

.days-locked p {
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    bottom: 15px;
}

.flex-div-wrap input{
   border: none;
   outline: none;
   background: linear-gradient(255.12deg, #242442 70%, #20203f 100%);
   font-size: 25px;
   padding: 5px 20px;
   width: 120px;
   margin-top: 3px;
   border-radius: 5px;
   border: 1px solid rgb(55, 80, 136);
   color: rgb(221, 221, 250);
}

.stake-button-wrap {
    display: flex;
    width: 100%;;
    position: absolute;
    bottom: 20px;
    
}

.stake-button-wrap button {
    background: rgb(221, 221, 250);
    color: rgb(15, 15, 39);
    padding: 12px;
    width: 270px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
    margin-left: 13px;
}

.stake-button-wrap button:hover {
    opacity: 0.7;
}

.withdraw-wrap {
    background: rgb(221, 221, 250);
    color: rgb(15, 15, 39);
    padding: 12px;
    width: 330px;
    height: 250px;
    border-radius: 10px;
    margin-left: 20px;
    padding: 30px 20px;
    position: relative;
}

.earning-div-flex {
    margin-bottom: 20px;
 }

.earning-div-flex p {
    font-size: 13px;
    color: rgb(17, 17, 26);
    padding-left: 5px;
    font-weight: 700;
 }

.earning-div-flex span {
   font-size: 40px;
   font-weight: 700;
   color: rgb(25, 25, 94);
}

.withdraw-wrap button {
    background: rgb(25, 25, 94);
    color:rgb(221, 221, 250);
    padding: 12px;
    width: 270px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    margin-left: 15px;
}

.withdraw-wrap button:hover {
    opacity: 0.9;
}

.withdraw-referal-container {
    display: flex;
    justify-content: space-between;
}

.referal-div-wrap {
    background: linear-gradient(255.12deg, #242444 70%, #282844 100%);
    color: rgb(221, 221, 250);
    padding: 12px;
    width: 70%;
    height: 250px;
    border-radius: 10px;
    margin-left: 20px;
    padding: 30px 20px;
    position: relative;
}

.ref-link-div {
    background: rgb(40, 40, 75);
    padding: 10px 20px ;
    color: rgb(221, 221, 250);
    border-radius: 5px;
    width: 86%;
    font-weight: 700;
}

.copy-div-wrap {
    background: rgb(221, 221, 250);
    width: 30px;
    padding: 7px;
    text-align: center;
    color: rgb(25, 25, 94);
    border-radius: 5px;
    cursor: pointer;
}

.ref-info-wrap {
    margin-top: 30px;
    font-size: 10px !important;
}

.ref-info-wrap p {
    font-size: 12px !important;
}

.ref-earning-wrap {
  margin: 30px 0;
}

.ref-earning-wrap span {
    color: rgb(226, 228, 255);
    font-size: 30px !important;
}

.deposit-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 17px;
}

.deposit-box {
    display: flex;
    flex-direction: column;
    background: linear-gradient(255.12deg, #536174 70%, #282844 100%);
    border-radius: 10px;
    margin-right: 30px;
    margin-bottom: 30px;
    padding: 20px;
    width: 340px;
    height: 200px;
    position: relative;
    box-sizing: border-box;
}

.terminate-btn {
    background: linear-gradient(255.12deg, #201a44 50%, rgb(210, 132, 255) 100%);
    color:rgb(221, 221, 250);
    padding: 10px;
    width: 170px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-weight: 800;
    font-size: 12px;
    cursor: pointer;
}

.terminate-btn:hover {
   opacity: 0.7;
}

.partners-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10vw;
    padding-bottom: 20px;
    color: rgb(54, 50, 83);
    font-size: 12px;
}

.withdraw-btn-wrap p {
    font-size: 13px;
    margin-top: 15px;
    margin-left: 20px;
    font-weight: 700;
    color: #f55c9c;
}

.minting-notice {
    position: fixed;
    background: linear-gradient(255.12deg, #dad6f8 0%, rgb(226, 228, 255) 100%);
    color: rgb(33, 35, 65);
    width: 200px;
    border-radius: 5px;
    text-align: center;
    padding: 15px;
    font-weight: 700;
    font-size: 12px;
    border-bottom: 5px solid rgb(38, 38, 59);
    right: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 12vh;
    z-index: 100;
}

.successful-trans {
    background: linear-gradient(255.12deg, #59ce8b 0%, rgb(226, 228, 255) 100%);
    margin-top: 10px;
}

.minting-img {
    opacity: 0.9;
}

.success-img {
    width: 30px;
}

.green-plan button {
    background: rgb(210, 132, 255) ;
}

.ig-new {
    color: rgb(247, 86, 86);
    width: 80vw;
    margin: 0 auto;
}

.count-down-wrap {
   width: 50%; 
   margin: 0 auto;
   text-align: center;
   color: rgb(248, 204, 60);
}

@media screen and (max-width: 890px) {
    .global-stats-container {
        flex-wrap: wrap;
    }

    .menu-container {
        flex-wrap: wrap;
    }

    .global-stats-box {
        margin-bottom: 20px;
    }

    .withdraw-referal-container {
        flex-wrap: wrap;
    }

    .referal-div-wrap {
        height: auto;
        margin-top: 30px;
        width: 80%;
    }

    .ref-link-div p{
        font-size: 8px;
    }

    .ref-info-wrap {
        display: none;
    }

    .body-container {
        flex-wrap: wrap;
    }

    .top-title {
        margin: 20px 0 !important;
    }

    .header-container {
        flex-wrap: wrap;
    }
    .top-prices {
        margin: 20px;
    }

    .global-stats-box {
        margin: 0;
        margin-bottom: 20px;
        width: 100%;
    }

    .anytime-plans-wrap {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        justify-content: center;
    }

    .plans-box-wrap {
        width: 95%;


    }

    .stake-button-wrap {
       margin-left: 0;
       align-items: center;  
    }

    .input-staking-wrap {
        display: flex;
        flex-direction: column;
        background: linear-gradient(255.12deg, #2d2d52 70%, #282844 100%);
        width: 90% !important;
        border-radius: 10px;
        padding: 10px 20px;
        align-items: center;
        padding-top: 30px;
        margin-right: 0;
    }

    .global-stats-wrap, .referal-div-wrap  {
        width: 80% !important;
    }

    .airdrop-box-wrap {
        left: 10px;
        width: 300px;
        padding-bottom: 10px;
    }

    .airdrop-icon {
        position: absolute !important;
        left: 50px;
        top: 20px;
    }
}
